import React, { useState } from "react";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
// import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function LoginDemo() {
  const config = require("../../config.json");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  //   const [password, setPassword] = useState("");
  const formSubmit = async (evt) => {
    try {
      setError("");
      evt.preventDefault();
      const { username, password } = evt.target.elements;
      // console.log(username.value);
      // console.log(password.value);
      let url = `${config.api_url}api/publisher/checkUser`;

      axios
        .post(
          `${url}`,
          {
            userName: username.value,
            password: password.value,
          },
          { withCredentials: true }
        )
        .then((response) => {
          console.log(response.data);
          const cookieString = document.cookie;
          console.log("Cookie:", document.cookie);

          navigate("/dashboard", { replace: true });
        })
        .catch((error) => {
          // Handle network errors or error responses
          if (error.response) {
            // The request was made, but the server responded with an error status code
            console.error(
              "Server responded with an error:",
              error.response.data
            );
            setError(error.response.data.message);
          } else if (error.request) {
            // The request was made, but no response was received (network error)
            console.error("No response received:", error.request);
            setError("No response received from server");
          } else {
            // Something happened in setting up the request that triggered an error
            console.error("Error:", error.message);
          }
        });

      // if (username.value === "ndoto" && password.value === "ndoto@123") {
      //   console.log("success");
      //   Cookies.set("ndoto", "ndoto", { expires: 7 });
      //   navigate("/dashboard", { replace: true });
      // }
    } catch (exception) {
      setError("Invalid Credentials");
    }
  };
  return (
    <div className=" h-screen flex items-center justify-center    overflow-hidden ">
      <div className="card">
        <form className="flex flex-column md:flex-row" onSubmit={formSubmit}>
          <div className="w-full md:w-5 flex flex-column align-items-s justify-content-center gap-3 py-5">
            <div className="flex flex-wrap justify-content-center align-items-center gap-2">
              <label htmlFor="username" className="w-6rem">
                Username
              </label>
              <InputText id="username" type="text" required />
            </div>
            <div className="flex flex-wrap justify-content-center align-items-center gap-2">
              <label htmlFor="password" className="w-6rem">
                Password
              </label>
              <InputText id="password" type="password" required />
            </div>
            <Button
              label="Login"
              icon="pi pi-user"
              className="w-10rem mx-auto"
            ></Button>
          </div>
          <div className="w-full md:w-2">
            <Divider layout="vertical" className="hidden md:flex">
              {/* <b>OR</b> */}
            </Divider>
            <Divider
              layout="horizontal"
              className="flex md:hidden"
              align="center"
            >
              <b>OR</b>
            </Divider>
          </div>
          <div className="w-full md:w-5 flex align-items-center justify-content-center py-5 flex-col">
            <Button
              type="submit"
              disabled
              label="Welcome"
              icon="pi pi-user-plus"
              className="p-button-success"
            ></Button>
            {error}
          </div>
        </form>
      </div>
    </div>
  );
}

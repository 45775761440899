import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Cookies from "js-cookie";
import axios from "axios";
import { ProgressSpinner } from "primereact/progressspinner";

// import { ProductService } from './service/ProductService';

export default function TodaysRevenue() {
  const config = require("../../config.json");
  const [publisherTraffic, setPublisherTraffic] = useState([]);
  const [liveSubs, setLiveSubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 400);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // http://localhost:4060/fetch-current-traffic
    // ProductService.getProductsMini().then(data => setProducts(data));
    const fetchCurrentDayData = async () => {
      try {
        await axios
          .post(
            `${config.api_url}fetch-current-traffic`,
            // `/fetch-current-traffic`,
            { clientName: JSON.parse(Cookies.get("userdashboard")).userName }
          )
          .then((response) => {
            console.log("HEEEEE");
            console.log(response.data);
            setPublisherTraffic(response.data.publisherTraffic);
            setLiveSubs(response.data.subData);
            setLoading(false);
          })
          .catch((error) => {
            // Handle network errors or error responses
            if (error.response) {
              // The request was made, but the server responded with an error status code
              console.error(
                "Server responded with an error:",
                error.response.status
              );
            } else if (error.request) {
              // The request was made, but no response was received (network error)
              console.error("No response received:", error.request);
            } else {
              // Something happened in setting up the request that triggered an error
              console.error("Error:", error.message);
            }
          });
      } catch (err) {
        console.error(err);
        alert("Some thing went wrong while fetching data");
      }
    };

    fetchCurrentDayData();
    return () => {
      // Cleanup logic (if required)
    };
  }, []);

  return (
    <div className="card">
      {loading ? (
        <div className=" min-h-screen flex justify-center items-center   flex-col">
          <h3
            className={` text-zinc-500   text-2xl text font-bold  ${
              isVisible ? "opacity-100" : "opacity-0"
            }`}
          >
            Data is Big so hold on.....
          </h3>
          <ProgressSpinner
            style={{ width: "50px", height: "50px", margin: "auto" }}
            strokeWidth="4"
          />
        </div>
      ) : (
        <>
          <DataTable
            value={liveSubs}
            tableStyle={{ minWidth: "50rem" }}
            header={
              <div className=" flex justify-center items-center text-orange-500 text-4xl">
                Total Subscriptions
              </div>
            }
          >
            <Column field="partner" header="partner"></Column>
            <Column field="servicename" header="Service Name"></Column>
            <Column field="ispending" header="Status"></Column>
            <Column field="total" header="Total"></Column>
          </DataTable>
          <DataTable
            value={publisherTraffic}
            tableStyle={{ minWidth: "50rem" }}
            header={
              <div className=" flex justify-center items-center text-indigo-700 text-4xl">
                Total Traffic
              </div>
            }
          >
            <Column field="partnerName" header="partner"></Column>
            <Column field="service" header="Service Name"></Column>
            <Column field="total" header="Total traffic today"></Column>
          </DataTable>
        </>
      )}
    </div>
  );
}

import { createBrowserRouter } from "react-router-dom";
import Login from "../../container/Login/Login";
import Dashboard from "../../container/Login/Dashboard/Dashboard";
import App from "../../App";
import Layout from "../Layout/Layout";
import ServiceWiseData from "../ServiceWiseData/ServiceWiseData";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <App />
      </Layout>
    ),
  },
  {
    path: "/login",
    element: (
      <Layout>
        <Login />
      </Layout>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <Layout>
        <Dashboard />
      </Layout>
    ),
  },
  {
    path: "/partner-wise/:month/:partner",
    element: (
      <Layout>
        <ServiceWiseData />
      </Layout>
    ),
  },
]);

import React from "react";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Tooltip } from "primereact/tooltip";

import moment from "moment";

const TableHeader = ({
  month,
  year,
  date,
  setMonth,
  setYear,
  isCalenderVisible = true,
  dataItem,
  dataSendingType,
}) => {
  console.log("DATA IS ", dataItem);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataItem);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, dataSendingType);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName +
            "_export_" +
            dataSendingType +
            "_" +
            monthNames[month - 1] +
            year +
            EXCEL_EXTENSION
        );
      }
    });
  };
  return (
    <div
      className={`flex flex-wrap align-items-center ${
        isCalenderVisible === true
          ? `justify-content-between`
          : `justify-center`
      }  gap-2`}
    >
      <Button
        type="button"
        icon="pi pi-file-excel"
        severity="success"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="Download XL"
        tooltip="Download XL"
      />
      <span className="text-xl text-900 font-bold">{`${
        monthNames[month - 1]
      }-${year}`}</span>
      {/* <Button icon="pi pi-refresh" rounded raised /> */}
      {isCalenderVisible && (
        <Calendar
          placeholder="select month"
          value={date}
          onChange={(e) => {
            setMonth(moment(e.value.toISOString()).format("MM"));
            setYear(moment(e.value.toISOString()).format("YYYY"));
          }}
          view="month"
          dateFormat="mm/yy"
          maxDate={new Date()}
          minDate={new Date("1 2022 November")}
        />
      )}
    </div>
  );
};

export default TableHeader;

// import "./App.css";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

// import Layout from "./components/Layout/Layout";

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    if (Cookies.get("ndoto") === "ndoto") navigate("/dashboard");
  }, []);
  return <div className="">Hi</div>;
}

export default App;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { ProgressSpinner } from "primereact/progressspinner";
import { TabView, TabPanel } from "primereact/tabview";
import Cookies from "js-cookie";

// import { Button } from "primereact/button";

import { DashBoardData } from "../../../components/DashBoardData/DashBoardData";
import TodaysRevenue from "../../../components/TodaysReveneue/TodaysReveneue";

const Dashboard = () => {
  const config = require("../../../config.json");
  const [loader, setLoader] = useState(true);
  const [date, setDate] = useState(null);
  const [dataSent, setDataSent] = useState([]);
  const [dataNotSent, setDataNotSent] = useState([]);
  // console.log("hi:", JSON.parse(Cookies.get("userdashboard")).userName);

  const d = new Date();

  const [month, setMonth] = useState(d.getMonth() + 1);
  const [year, setYear] = useState(d.getFullYear());
  //
  //   console.log(month);
  //   console.log(year);

  useEffect(() => {
    setLoader(true);
    try {
      const url = `${
        config.api_url
      }fetch-month-wise?month=${month}&year=${year}&clientName=${
        JSON.parse(Cookies.get("userdashboard")).userName
      }`;
      // const url = `/fetch-month-wise?month=${month}&year=${year}`;
      const fetchData = async () => {
        const response = await axios.get(`${url}`).catch((error) => {
          // Handle network errors or error responses
          if (error.response) {
            // The request was made, but the server responded with an error status code
            console.error(
              "Server responded with an error:",
              error.response.status
            );
          } else if (error.request) {
            alert("No response from backend");
            // The request was made, but no response was received (network error)
            console.error("No response received:", error.request);
          } else {
            // Something happened in setting up the request that triggered an error
            console.error("Error:", error.message);
          }
        });
        //   console.log("RESPONSE  ", response);
        return response.data;
      };
      fetchData().then((result) => {
        setLoader(false);
        console.log(result);
        setDataSent(result.resultSent);
        setDataNotSent(result.resultNotSent);
      });
    } catch (err) {
      console.log("err some thing is wrong");
    }
  }, [month]);
  // console.log("hey env ", process.env.REACT_APP_API_KEY);

  if (loader)
    return (
      <div className=" h-screen flex justify-center items-center">
        <ProgressSpinner />;
      </div>
    );
  // console.log(
  //   "Last update data ",
  //   dataSent[0].last_update_date.substring(0, 10)
  // );
  return (
    <div className="card">
      <TabView>
        <TabPanel header="Month Trend">
          <DashBoardData
            dataSent={dataSent}
            month={month}
            year={year}
            dataNotSent={dataNotSent}
            setMonth={setMonth}
            setYear={setYear}
            date={date}
          />
        </TabPanel>

        <TabPanel header="Live Traffic">
          <TodaysRevenue />
        </TabPanel>
      </TabView>
    </div>
    // <>

    // </>
  );
};

export default Dashboard;

import React from "react";
import DataTableItem from "../DataTable/DataTable";
import TableHeader from "../TableHeader/TableHeader";

export const DashBoardData = ({
  dataSent,
  month,
  year,
  dataNotSent,
  setMonth,
  setYear,
  date,
}) => {
  return (
    <div>
      {dataSent.length > 0 && (
        <div>
          <h1 className="  text-center  font-bold p-2 bg-slate-400   border-spacing-2 mb-2 rounded  ">
            {dataSent[0].last_update_date !== null &&
              `Last updated Data is ${dataSent[0].last_update_date.substring(
                0,
                10
              )}`}
          </h1>
        </div>
      )}

      <div className="card ">
        <DataTableItem
          header={
            <TableHeader
              dataItem={dataSent}
              month={month}
              year={year}
              date={date}
              setMonth={setMonth}
              setYear={setYear}
              dataSendingType="sentToPub"
            />
          }
          data={dataSent}
          dataHeader="Total Sent to Publisher"
        />
        <DataTableItem
          data={dataNotSent}
          dataHeader="Total Not Sent to Publisher"
          header={
            <TableHeader
              dataItem={dataNotSent}
              month={month}
              year={year}
              date={date}
              setMonth={setMonth}
              setYear={setYear}
              isCalenderVisible={false}
              dataSendingType="NotsentToPub"
            />
          }
        />
      </div>
    </div>
  );
};

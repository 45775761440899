import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import axios from "axios";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Cookies from "js-cookie";

const ServiceWiseData = () => {
  const config = require("../../config.json");
  const { partner, month } = useParams();
  const [loader, setLoader] = useState(true);
  const [sentData, setSentData] = useState([]);
  const [notSentData, setNotSentData] = useState([]);
  useEffect(() => {
    const fetchPartnerwiseData = async () => {
      try {
        // const urlFetch = `http://localhost:4060/partner-service-wise?month=${month}&partner=${partner}`;
        const urlFetch = `${
          config.api_url
        }partner-service-wise?month=${month}&partner=${partner}&clientName=${
          JSON.parse(Cookies.get("userdashboard")).userName
        }`;
        const response = await axios(`${urlFetch}`);
        console.log(response.data);
        setSentData([...response.data.sent]);
        setNotSentData([...response.data.notSent]);
        setLoader(false);
      } catch (err) {
        // console.log(err);
        alert("Some thing went wrong while fetching data");
        setLoader(false);
      }
    };

    fetchPartnerwiseData();
  }, []);

  console.log(sentData, notSentData);
  if (loader)
    return (
      <div className=" h-screen flex justify-center items-center">
        <ProgressSpinner />;
      </div>
    );
  return (
    <div className="card">
      <TabView>
        <TabPanel header="Data Sent">
          <DataTable
            className=" mt-2"
            // className="flex justify-center"
            value={sentData}
            tableStyle={{ minWidth: "50rem" }}
            showGridlines
            // header={header}
          >
            <Column
              field="partner"
              header="Partner"
              sortable
              //   bodyStyle={{ textAlign: "center", overflow: "visible" }}
              headerStyle={{
                width: "10rem",
                textAlign: "center",
                background: "cyan",
              }}
            ></Column>
            <Column
              field="servicename"
              header="servicename"
              sortable
              //   bodyStyle={{ textAlign: "center", overflow: "visible" }}
              headerStyle={{
                width: "10rem",
                textAlign: "center",
                background: "cyan",
              }}
            ></Column>
            <Column
              field="postbackSent"
              header="postbackSent"
              sortable
              //   bodyStyle={{ textAlign: "center", overflow: "visible" }}
              headerStyle={{
                width: "10rem",
                textAlign: "center",
                background: "cyan",
              }}
            ></Column>
          </DataTable>
        </TabPanel>
        <TabPanel header="Data Not Sent">
          <DataTable
            className=" mt-2"
            // className="flex justify-center"
            value={notSentData}
            tableStyle={{ minWidth: "50rem" }}
            showGridlines
            // header={header}
          >
            <Column
              field="partner"
              header="Partner"
              sortable
              //   bodyStyle={{ textAlign: "center", overflow: "visible" }}
              headerStyle={{
                width: "10rem",
                textAlign: "center",
                background: "cyan",
              }}
            ></Column>
            <Column
              field="servicename"
              header="servicename"
              sortable
              //   bodyStyle={{ textAlign: "center", overflow: "visible" }}
              headerStyle={{
                width: "10rem",
                textAlign: "center",
                background: "cyan",
              }}
            ></Column>
            <Column
              field="postbacknotSent"
              header="postbacknotSent"
              sortable
              //   bodyStyle={{ textAlign: "center", overflow: "visible" }}
              headerStyle={{
                width: "10rem",
                textAlign: "center",
                background: "cyan",
              }}
            ></Column>
          </DataTable>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default ServiceWiseData;

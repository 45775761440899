import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const DataTableItem = ({ data, header, dataHeader }) => {
  const navigate = useNavigate();
  const lockTemplate = (rowData) => {
    console.log(rowData);
    return (
      <Button
        type="button"
        className="p-button-sm p-button-text"
        onClick={() => {
          console.log(rowData);
          navigate(`/partner-wise/${rowData.month}/${rowData.partner}`);
        }}
      >
        Show Services
      </Button>
    );
  };
  return (
    <DataTable
      className=" mt-2"
      // className="flex justify-center"
      value={data}
      tableStyle={{ minWidth: "50rem" }}
      showGridlines
      header={header}
    >
      <Column
        field="partner"
        header="Partner"
        sortable
        //   bodyStyle={{ textAlign: "center", overflow: "visible" }}
        headerStyle={{
          width: "10rem",
          textAlign: "center",
          background: "cyan",
        }}
        heade
      ></Column>
      <Column
        field="total"
        header={dataHeader}
        sortable
        //   bodyStyle={{ textAlign: "center", overflow: "visible" }}
        headerStyle={{
          width: "10rem",
          textAlign: "center",
          background: "cyan",
        }}
      ></Column>
      <Column
        field="deducted_amount"
        header="TotalAmount in ZAR"
        sortable
        //   bodyStyle={{ textAlign: "center", overflow: "visible" }}
        headerStyle={{
          width: "10rem",
          textAlign: "center",
          background: "cyan",
        }}
      ></Column>
      <Column
        header="Show Services"
        body={lockTemplate}
        exportable={false}
        style={{ minWidth: "12rem" }}
        headerStyle={{
          width: "10rem",
          textAlign: "center",
          background: "cyan",
        }}
      ></Column>
      {/* <Column
        field="USD"
        header="TotalAmount In Usd(1 USD=20XAR)"
        sortable
        //   bodyStyle={{ textAlign: "center", overflow: "visible" }}
        headerStyle={{
          width: "10rem",
          textAlign: "center",
          background: "cyan",
        }}
      ></Column> */}
      {/* <Column field="quantity" header="Quantity"></Column> */}
    </DataTable>
  );
};

export default DataTableItem;

import React from "react";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  // console.log("cookies outside", Cookies.get("userdashboard"));

  const signOut = () => {
    Cookies.remove("userdashboard");
    navigate("/login", { replace: true });
  };
  useEffect(() => {
    // console.log("cookies", Cookies.get("userdashboard"));
    if (Cookies.get("userdashboard") === undefined)
      navigate("/login", { replace: true });
    // else navigate("/login", { replace: true });
  }, [navigate]);
  return (
    <div>
      <div className="flex     text-lg space-x-8 font-bold  items-center  justify-between ">
        {Cookies.get("userdashboard") !== undefined && (
          <>
            <div
              className="pi pi-home cursor-pointer  text-slate-700 hover:text-blue-600"
              style={{ fontSize: "2rem" }}
              onClick={() => navigate("/dashboard")}
            ></div>
            <Button label="sign-out" className=" mb-2" onClick={signOut} />
          </>
        )}
      </div>
      <div className="  bg-[#8EC5FC] p-10 overflow-hidden ">{children}</div>;
    </div>
  );
};

export default Layout;
